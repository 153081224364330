@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Arenq';
  src: url('./fonts/arenq.otf');
}

@font-face {
  font-family: 'Wadoend';
  src: url('./fonts/wadoend.otf');
}


@font-face {
  font-family: 'Mova';
  src: url('./fonts/mova.otf');
}

@font-face {
  font-family: 'Beckman';
  src: url('./fonts/beckman.otf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  color-scheme: dark;
}

.nav-links::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: #00EEFF;
  margin: auto;
  transition: 0.5s;
}

.nav-links:hover::after {
  width: 70%;
}


.hero-heading {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.hash-span {
  margin-top: -1.5em;
  display: block;
}

.vertical-timeline.vertical-timeline-custom-line::before {
  background: rgb(66, 66, 66);
  background: linear-gradient(
    180deg,
    rgba(66, 66, 66, 0.8) 40%,
    rgba(234, 234, 236, 0.45) 100%
  );
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #afafafd2, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.glassmorphism {
  background: rgba(20, 20, 20, 0.7);
  box-shadow: 0 8px 32px 0 rgba(133, 133, 133, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.card-shadow {
  box-shadow: rgba(155, 155, 155, 0.3) 0 2px 6px,
    rgba(155, 155, 155, 0.22) 0 0 4px;
  border-radius: 24px;
}

.black-gradient {
  background: #000000;
  background: -webkit-linear-gradient(to right, #434343, #000000);
  background: linear-gradient(to right, #434343, #000000);
}

.bw-gradient {
  background: rgb(10, 10, 10);
  background: linear-gradient(
    180deg,
    rgba(10, 10, 10, 0.8) 40%,
    rgba(153, 153, 161, 0) 70%
  );
}

.card-gradient {
  background: rgb(244, 244, 246);
  background: linear-gradient(
    158deg,
    rgba(153, 153, 161, 1) 25%,
    rgba(153, 153, 161, 1) 100%
  );
}

/* canvas- styles */
.canvas-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

.nav-bg{
  background-color: #292929;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar track */
  width: 5rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f44f4d; /* You can change this to the color you want */
  border-radius: 10px;
  height: 5rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #f44f4d;
  width: 25px;
}
.menu-bg-radius{
  border-bottom-left-radius: 95%;
}
.about-border {
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  border-bottom-left-radius: 18em;
}
/* .about-border{
  border-left: 2px solid #00EEFF; 
  border-bottom: 2px solid #00EEFF; 
} */
.about-border::before, .about-border::after {
  content: ''; /* Both pseudo-elements need content */
  position: absolute;
  box-sizing: border-box;
  border-bottom-left-radius: 18em;
}


.about-border::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #00EEFF;
  box-shadow: 0 0 12px #00EEFF;
  clip-path: polygon(0 100%, 100% 100%, 0% 1%, 0 50%);
}


.services-border {
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  border-bottom-right-radius: 18em;
}
/* .about-border{
  border-left: 2px solid #00EEFF; 
  border-bottom: 2px solid #00EEFF; 
} */
.services-border::before, .services-border::after {
  content: ''; /* Both pseudo-elements need content */
  position: absolute;
  box-sizing: border-box;
  border-bottom-right-radius: 18em;
}


.services-border::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #00EEFF;
  box-shadow: 0 0 12px #00EEFF;
  clip-path: polygon(100% 100%, 100% 0%, 0% 100%, 100% 100%);
}

.vertical-timeline-element-date{
  color: #f3e6d3 !important;
}


.progress-bar {
  width: 100%;
  background-color: #f3e6d3;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-completed {
  height: 0.5em;
  border-radius: 1em;
  background-color: #00EEFF;
  transition: width 1s ease-in-out;
}

.skill-card{
  width: 5em;
  height: 5em;
  padding: 1em;
  
  img{
    border-radius: 50%;
  }
}


.tech-icon-bg{
  background: linear-gradient(135deg, rgba(10,10,10,0.5) 50%, rgba(0,238,255,0.2) 50%);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.other-skill{
  background: transparent;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  img{
    border-radius: 50%;
  }
}

.others-card{
  background:  rgba(0,238,255,0.2);
  border-radius: 10%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact-card-background {
  background: linear-gradient(225deg, rgba(10,10,10,0.2) 60%, rgba(0,238,255,0.1) 90%); /* Light, semi-transparent white */
  backdrop-filter: blur(10px);           /* Blur effect */
  -webkit-backdrop-filter: blur(10px);   /* For Safari */
}

/* loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it appears on top of everything */
}

.loader {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  width: 25rem;
  height: 25rem;
}

.loading-spinner{
    display: flex;
    flex: 1;
    justify-content: center;
    color: #00eeff;
    align-items: center;
}